
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '420'
        }, [this.VehicleSpecs(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.firstSelectedTerm || '',
                                'disabled': this.disabled,
                                'custom-value': this.firstSelectedTerm || ''
                            }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', '', this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '6721'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '6724'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_VehicleSpecs' }, this.selects.length ? _createElement('div', {
                    'className': 'cm_vehicle-widget cm_vehicle-widget__specs',
                    'key': '29'
                }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facettitle' }, 'Vehicle specs'), _createElement('div', { 'className': 'facetbody cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', {
                    'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                    'title': 'Reset',
                    'onClick': this.discardExtraFields
                }, '\n        Reset\n      ')))) : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeDollarInchMm1_rangeChip() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '630' }, dollar, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '738' }, dollar, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'image',
                                                'viewBox': '0 0 22 22',
                                                'key': '8760'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarWheels(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.firstSelectedTerm || '',
                                    'disabled': this.disabled,
                                    'custom-value': this.firstSelectedTerm || ''
                                }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n        ', '', this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7821'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7824'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)))]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Wheels';
                    return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '43400'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarTires(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.firstSelectedTerm || '',
                                    'disabled': this.disabled,
                                    'custom-value': this.firstSelectedTerm || ''
                                }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n        ', '', this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7821'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7824'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)))]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop Tires';
                    return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar facetholder' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title facettitle' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection,
                        'role': 'button',
                        'tabIndex': this.hasSelection ? '0' : '-1'
                    }, [_createElement('svg', {
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'className': 'cm_icon cm_icon-reset',
                            'height': '16px',
                            'viewBox': '0 0 16 16',
                            'key': '43380'
                        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.Facets(function () {
                return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, '$', this.range[0], ' - $', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '592' }, '$', this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '668' }, '$', this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeDollarInchMm4() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '10221'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn cm_button cm_button__secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '1650'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '4860'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1006'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1092'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1412'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '15811'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3412'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3738'
                                    }, [this.select(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.firstSelectedTerm || '',
                                                'disabled': this.disabled,
                                                'custom-value': this.firstSelectedTerm || ''
                                            }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n        ', '', this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6951'
                                                }, 'Popular Makes'),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6954'
                                                }, 'All Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '7205'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '72891' }, '$', this.selectedRange[0], ' to $', this.selectedRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '72893'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '75651'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn cm_button cm_button__secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '12141'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.firstSelectedTerm || '',
                            'disabled': this.disabled,
                            'custom-value': this.firstSelectedTerm || ''
                        }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n        ', '', this.title, '\n      ') : null, this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '6721'
                            }, 'Popular Makes'),
                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '6724'
                            }, 'All Makes'),
                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                        ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button cm_button__primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '42940'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '4815'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_button cm_button__primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_button cm_button__secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '740'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8150'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('div', {
                            'className': 'product-item',
                            'data-product-id': this.id
                        }, _createElement('div', { 'className': 'card ajax-loaded' }, _createElement('div', { 'className': 'card-product' }, _createElement('div', { 'className': 'card-product__wrapper' }, _createElement('div', { 'className': 'card__badge badge-right halo-productBadges halo-productBadges--right' }, this.on_sale ? _createElement('span', {
                            'className': 'badge sale-badge',
                            'aria-hidden': 'true',
                            'key': '295'
                        }, 'Sale') : null), _createElement('a', {
                            'className': 'card-media card-media--portrait media--hover-effect media--loading-effect',
                            'href': this.url,
                            'style': { paddingBottom: '90%' }
                        }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'title': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'loading': 'lazy',
                            'className': 'motion-reduce lazyloaded'
                        }), this.image2 ? _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image2)),
                            'alt': this.removeHTML(this.title),
                            'title': this.removeHTML(this.title),
                            'onError': e => this.onImageError(e, 'image2'),
                            'loading': 'lazy',
                            'className': 'motion-reduce lazyloaded',
                            'key': '778'
                        }) : null), _createElement('div', { 'className': 'card-product__group group-left' }, _createElement('div', { 'className': 'card-product__group-item card-wishlist' }, _createElement('a', {
                            'className': 'wishlist-icon',
                            'href': '#',
                            'data-wishlist': true,
                            'data-wishlist-handle': this.handle,
                            'data-product-id': this.id
                        }, _createElement('span', { 'className': 'visually-hidden' }, 'Add to wishlist'), _createElement('span', { 'className': 'text' }, ' Add to wishlist '), _createElement('svg', {
                            'viewBox': '0 0 512 512',
                            'className': 'icon icon-wishlist'
                        }, _createElement('g', {}, _createElement('g', {}, _createElement('path', { 'd': 'M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25\n      c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25\n      c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7\n      c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574\n      c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431\n      c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351\n      C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646\n      c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245\n      C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659\n      c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66\n      c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351\n      C482,254.358,413.255,312.939,309.193,401.614z' }))))))))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'card-information__wrapper text-left' }, _createElement('div', { 'className': 'card-information__group card-information__group-2' }, _createElement('div', { 'className': 'card-vendor link-underline' }, _createElement('span', { 'className': 'visually-hidden' }, 'Vendor:'), _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } })))), _createElement('a', {
                            'className': 'card-title link-underline card-title-ellipsis',
                            'href': this.url,
                            'data-product-title': this.removeHTML(this.title),
                            'data-product-url': this.url
                        }, _createElement('span', mergeProps({ 'className': 'text' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-price' }, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', { 'className': 'price__last' }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'price__sale' }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('dd', { 'className': 'price__last' }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)))))))), _createElement('div', { 'className': 'card-action' }, this.ProductScope !== 'master' && this.variant_ids.length <= 1 && !this.out_of_stock ? _createElement('form', {
                            'action': '/cart/add',
                            'method': 'post',
                            'className': 'variants',
                            'data-product-id': this.id,
                            'encType': 'multipart/form-data',
                            'key': '4654'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids
                        }), _createElement('input', {
                            'type': 'hidden',
                            'name': 'quantity',
                            'value': '1'
                        }), _createElement('button', {
                            'type': 'submit',
                            'className': 'product-form__submit button button--primary an-',
                            'id': 'product-add-to-cart'
                        }, '\n              Add to cart\n            ')) : null, this.ProductScope === 'master' || this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('a', {
                            'href': this.url,
                            'className': 'button button-ATC',
                            'data-product-handle': this.handle,
                            'key': '5216'
                        }, '\n            Choose Options\n          ') : null, this.ProductScope !== 'master' && this.out_of_stock ? _createElement('button', {
                            'disabled': 'true',
                            'className': 'button button-ATC',
                            'data-product-handle': this.handle,
                            'key': '5465'
                        }, '\n            Out of Stock\n          ') : null)))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse cm_button cm_button__primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })])));
}
        export const componentNames = ["cm:VehicleSpecs","cm:filterChips","cm:FilterChips","cm:FacetBarWheels","cm:FacetBarTires","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:SearchVehicleWidget","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]